<template>
  <div class="container col-12 d-flex justify-content-center">
    <div class="content col-12 flex justify-content-center align-items-center">

      <div class="row ">
        <div class="col-sm-2 col-12"></div>
        <div class="col-sm-8 col-12">
          <h1 class="mt-3" v-if="this.user.id === 0">Gestionar Usuario</h1>
          <h1 class="mt-3" v-else>Editar Usuario</h1>
          <hr />
          <form-tag @userEditEvent="submitHandler" name="userform" event="userEditEvent">
            <label for="fisrt-name" class="mb-2">Nombre</label>
            <text-input v-model="user.first_name" type="text" required="true" label="First Name" :value="user.first_name"
              name="first-name">
            </text-input>

            <label for="last-name" class="mt-2 mb-2">Apellido</label>
            <text-input v-model="user.last_name" type="text" required="true" label="Last Name" :value="user.last_name"
              name="last-name">
            </text-input>

            <label for="email" class="mt-2 mb-2">Correo Electronico</label>
            <text-input v-model="user.email" type="email" required="true" label="Email" :value="user.email" name="email">
            </text-input>


            <label for="password" class="mt-2 mb-2">Contraseña</label>
            <text-input v-if="this.user.id === 0" v-model="user.password" type="password" required="true" label="Password"
              :value="user.password" name="password">
            </text-input>

            <text-input v-else v-model="user.password" type="password" label="Password"
              help="Leave empty to keep existing password" :value="user.password" name="password">
            </text-input>
            <br>
            <h4>Roles</h4>
            <div class="form-group row ms-3">
              <div class="col-sm-6 col-6">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Admin" id="flexCheckDefault"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckDefault">
                    Admin
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Coordinacion" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Coordinacion
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Coordinador Sucursal" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Coordinador Sucursal
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Jefa Financiamiento" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Jefa Financiamiento
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Modo Thanos" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                      Modo Thanos
                  </label>
                </div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Finanzas" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Finanzas
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Talento Humano" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Talento Humano
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Asesor" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Asesor
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Consultor" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Consultor
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Gestor Archivos" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Gestor Archivos
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Oficina General" id="flexCheckChecked"
                    v-model="this.user.roles" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Oficina General
                  </label>
                </div>
              </div>
              
            </div>

     
            <h4>Sucursal</h4>
     
     <div class="form-group row ms-3">
<div class="col-sm-6 col-6">
<div class="form-check">
<input class="form-check-input" type="checkbox" value="FRANCISCO MORAZAN" id="flexCheckFM" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckFM">FRANCISCO MORAZAN</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="EL PARAISO" id="flexCheckEP" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckEP">EL PARAISO</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="ATLANTIDA" id="flexCheckAT" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckAT">ATLANTIDA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="COPAN" id="flexCheckCP" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckCP">COPAN</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="LEMPIRA" id="flexCheckLP" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckLP">LEMPIRA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="OCOTEPEQUE" id="flexCheckOC" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckOC">OCOTEPEQUE</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="OLANCHO" id="flexCheckOL" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckOL">OLANCHO</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="SANTA BARBARA" id="flexCheckSB" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckSB">SANTA BARBARA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="COMAYAGUA AGRICOLA" id="flexCheckCA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckCA">COMAYAGUA AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="OLANCHO AGRICOLA" id="flexCheckOLA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckOLA">OLANCHO AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="ATLANTIDA AGRICOLA" id="flexCheckATA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckATA">ATLANTIDA AGRICOLA</label>
</div>
</div>
<div class="col-sm-6 col-6">


<div class="form-check">
<input class="form-check-input" type="checkbox" value="CHOLUTECA AGRICOLA" id="flexCheckCHA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckCHA">CHOLUTECA AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="COLON AGRICOLA" id="flexCheckCLA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckCLA">COLON AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="EL PARAISO AGRICOLA" id="flexCheckEPA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckEPA">EL PARAISO AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="FRANCISCO MORAZAN AGRICOLA" id="flexCheckFMA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckFMA">FRANCISCO MORAZAN AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="INTIBUCA AGRICOLA" id="flexCheckIA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckIA">INTIBUCA AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="LEMPIRA AGRICOLA" id="flexCheckLPA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckLPA">LEMPIRA AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="LEMPIRA/TOMALA" id="flexCheckLPT" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckLPT">LEMPIRA/TOMALA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="LEMPIRA/ERANDIQUE" id="flexCheckLPE" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckLPE">LEMPIRA/ERANDIQUE</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="YORO AGRICOLA" id="flexCheckYA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckYA">YORO AGRICOLA</label>
</div>
<div class="form-check">
<input class="form-check-input" type="checkbox" value="ALL" id="flexCheckYA" v-model="this.sucursal">
<label class="form-check-label" for="flexCheckYA">ALL</label>
</div>
</div>
</div>

            <br>
            <h4>Estado del Usuario</h4>
            <div class="form-group row ms-3">
              <div class="col-sm-6 col-6">

                <div class="form-check">
                  <input v-model="user.active" type="radio" class="form-check-input" id="user-active" :value="1">
                  <label for="user-active" class="form-check-label">Active</label>
                </div>
                <div class="form-check">
                  <input v-model="user.active" type="radio" class="form-check-input" id="user-active" :value="0">
                  <label for="user-active-2" class="form-check-label">Inactive</label>
                </div>
              </div>
            </div>

            <hr />

            <div class="float-start">
              <input type="submit" class="btn btn-primary me-2" value="Save" />
              <router-link to="/ahdecoAdminSettings" class="btn btn-outline-secondary">Cancel</router-link>
            </div>
            <div class="float-end">
              <a v-if="this.$route.params.userId > 0 &&
                parseInt(String(this.$route.params.userId), 10) !==
                store.user.id
                " class="btn btn-danger" href="javascript:void(0);" @click="condirmDelete(this.user.id)">Delete</a>
            </div>
            <div class="clearfix"></div>
          </form-tag>
        </div>

        <div class="col-3"></div>


      </div>
    </div>
  </div>
</template>

<script>
import Security from "../security";
import FormTag from "../forms/Form-Tag.vue";
import TextInput from "../forms/Text-Input.vue";
import notie from "notie";
import { store } from "../store";
import router from '../../router/index.js'
import direccionData from '../solicitud/direccionData.json';

export default {
  beforeMount() {
    Security.requireToken();
    this.departamentos = direccionData[0].departamentos
    this.departamentos.push({ idDepartamento: 0, nombre: "ALL" })
    if (parseInt(String(this.$route.params.userId), 10) > 0) {
      //editing an existing user
      fetch(process.env.VUE_APP_API_URL + "/admin/users/get/" + this.$route.params.userId, Security.requestOptions(""))
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.$emit('error', data.message);
          } else {
            this.user = data;
            console.log(data)
            this.sucursal = data.sucursal
            //we want password to be empty for existing users
            this.user.password = "";
          }
        })
    }
  },
  data() {
    return {
      departamentos: [],
      departamentoSeleccionado: null,
      admin: false,
      coordinacion: false,
      finanzas: false,
      talentoHumano: false,
      user: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        active: 0,
        sucursal: [],
        roles: [],
      },
      sucursal: [],
      store,
    };
  },
  components: {
    "form-tag": FormTag,
    "text-input": TextInput,
  },
  watch: {
    'sucursal': function (val) {
      this.user.sucursal = val
      console.log(this.user.sucursal)
    },
    'user.roles': function (val) {
      console.log(val)
    }
  },
  methods: {
    submitHandler() {
      const payload = {
        id: parseInt(String(this.$route.params.userId), 10),
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        password: this.user.password,
        active: this.user.active,
        roles: this.user.roles,
        sucursal: this.sucursal
      };
      console.log(payload)
      fetch(
        `${process.env.VUE_APP_API_URL}/admin/users/save`,
        Security.requestOptions(payload)
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.$emit('error', data.message);
          } else {
            this.$emit('success', "Changes saved!");
            router.push("/ahdecoAdminSettings");
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        });
    },
    condirmDelete(id) {
      notie.confirm({
        text: "Are you suer you want to delete this user?",
        submitText: "Delete",
        submitCallback: function () {
          console.log("Will delete", id);

          let payload = {
            id: id,
          }

          fetch(process.env.VUE_APP_API_URL + "/admin/users/delete", Security.requestOptions(payload))
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                notie.alert({
                  type: "error",
                  text: data.message,
                })
              } else {
                notie.alert({
                  type: "success",
                  text: "User deleted"
                })
                router.push("/ahdecoAdminSettings")
              }
            })
        }
      })
    },
  },
};
</script>
