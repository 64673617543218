<template>
    <div class="container-fluid p-5 mt-3">

        <div class="row">
            <div class="col-12 col-lg-4  ">
                <div v-if="ready" class=" ps-5 info-beneficiario">
                    <h4 class="display-4">{{ this.credito?.cliente.nombres }}
                        {{ (this.credito?.cliente.apellidos) ? (this.credito?.cliente.apellidos) : "" }}</h4>
                    <h4 class="display-5">{{ this.credito?.cliente.dni }}</h4>
                </div>


                <div class="subir-archivos">
                    <div class="accordion accordion-flush " id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false"
                                    aria-controls="flush-collapseOne">
                                    Agregar Archivos
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse"
                                data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div v-if="this.Admin || this.gestorArchivos" class="mb-3">
                                        <label for="dniFile" class="form-label">Sube la Identidad del Beneficiario
                                            (DNI)</label>
                                        <input class="form-control" type="file" id="dniFile" accept="image/*"
                                            @change="handleFileChange($event, 'dni')">
                                    </div>
                                    <div v-if="this.Admin || this.gestorArchivos" class="mb-3">
                                        <label for="fachadaFile" class="form-label">Sube foto de la fachada del
                                            Negocio</label>
                                        <input class="form-control" type="file" id="fachadaFile" accept="image/*"
                                            @change="handleFileChange($event, 'fachada')">
                                    </div>
                                    <div v-if="this.Admin || this.gestorArchivos" class="mb-3">
                                        <label for="beneficiarioFile" class="form-label">Sube foto del
                                            beneficiario</label>
                                        <input class="form-control" type="file" id="beneficiarioFile" accept="image/*"
                                            @change="handleFileChange($event, 'beneficiario')">
                                    </div>
                                    <div v-if="this.Admin || this.gestorArchivos" class="mb-3">
                                        <label for="expedienteFile" class="form-label">Sube el expediente</label>
                                        <input class="form-control" type="file" id="expedienteFile"
                                            @change="handleFileChange($event, 'expediente')">
                                    </div>
                                    <div class="mb-3">
                                        <label for="otrosFiles" class="form-label">Otros</label>
                                        <input class="form-control" type="file" id="otrosFiles" multiple
                                            @change="handleFileChange($event, 'otros')">
                                    </div>

                                    <button class="btn btn-primary" @click="uploadFiles">Guardar</button>
                                </div>

                            </div>


                        </div>
                        <br>
                        <div>
                            <b>Tamaño archivos:</b>
                            <p>{{ this.totalSize }}</p>
                        </div>
                    </div>


                </div>

            </div>

            <!-- Lado derecho -->
            <div class="col-12 col-lg-8 d-flex flex-column align-items-center">
                <!-- Muestra una vista previa de los archivos subidos en formato grid estilo Google Drive -->
                <div v-if="readyArchivos" class="file-grid">
                    <div v-for="(files, key) in showFiles" :key="key">

                        <div v-if="key != 'otros' && ((this.oficinaGeneral) ? (true) : key != 'expediente')">
                            <h5 v-if="showFiles[key].length != 0" class="text-center">{{ key.toUpperCase() }}</h5>
                            <div class="file-grid-item" v-for="(file, index) in files" :key="index">
                                <div v-if="isImage(file)">
                                    <img :src="createImagePreview(file)" class="file-preview-img"
                                        alt="Vista previa de imagen">
                                </div>

                                <div v-else class="file-icon">
                                    <i :class="getFileIcon(file)" class="fas fa-file"></i>
                                    <p>{{ file.name }}</p>
                                </div>
                                <p class="file-format">Formato: {{ getFileFormat(file) }}</p>

                                <p v-if="estaGuardado(file, key)" class="text-primary">Sin Guardar</p>
                                <div class="file-buttons">
                                    <button class="btn btn-light btn-sm" @click="deleteFile(file, key)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                    <!-- Button trigger modal -->


                                    <button class="btn  btn-light btn-sm" @click="downloadFile(file)">
                                        <i class="fas fa-download"></i>
                                    </button>
                                </div>
                                <div class="row p-3">
                                    <button @click="mostrarArchivo(file, key)" type="button" class="btn visualizar"
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                        style="background-color: #007BFF; color: white; border-radius: 5px; ">
                                        <i class="fa-solid fa-eye"></i>

                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <div v-else>
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>


                <br>

                <div v-for="(files, key) in showFiles" :key="key">
                    <h5 v-if="showFiles[key].length != 0" class="text-center">{{ (key === "otros" ? key.toUpperCase() :
                        "") }}</h5>
                    <div v-if="key === 'otros'" class="d-flex flex-wrap " style="width: 100%;">

                        <div class="file-grid-item me-2" v-for="(file, index) in files" :key="index">
                            <div v-if="isImage(file)">
                                <img :src="createImagePreview(file)" class="file-preview-img"
                                    alt="Vista previa de imagen">
                            </div>
                            <div v-else class="file-icon">
                                <i :class="getFileIcon(file)" class="fas fa-file"></i>
                                <p>{{ file.name }}</p>
                            </div>
                            <p class="file-format">Formato: {{ getFileFormat(file) }}</p>
                            <p v-if="estaGuardado(file, key)" class="text-primary">Sin Guardar</p>
                            <div class="file-buttons">
                                <button class="btn btn-light btn-sm" @click="deleteFile(file, key)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                <!-- <button @click="mostrarArchivo(file, key)" type="button" class="btn btn-light btn-sm"
                                    data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    <i class="fa-solid fa-eye"></i>
                                </button> -->
                                <button class="btn btn-light btn-sm" @click="downloadFile(file)">
                                    <i class="fas fa-download"></i>
                                </button>
                            </div>
                            <div class="row p-3">
                                <button @click="mostrarArchivo(file, key)" type="button" class="btn visualizar"
                                    data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                    style="background-color: #007BFF; color: white; border-radius: 5px; ">
                                    <i class="fa-solid fa-eye"></i>

                                </button>
                            </div>


                        </div>
                    </div>

                </div>

                <!-- Modal -->
                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Visualizador de Documentos</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <embed v-if="base64 != ''" :src="base64.archivoBase64"
                                    :type="generarMimeType(base64.formato)" width="100%" height="100%" controls="0" />


                                <!-- <object v-if="base64 != ''" :data="base64.archivoBase64"
                                    :type="generarMimeType(base64.formato)" width="100%" height="100%">
                                    <p>Tu navegador no soporta la visualización de este archivo.</p>
                                </object> -->

                                <!-- <iframe v-if="base64 != ''" :src="base64.archivoBase64" :title="base64.formato"
                                    width="100%" height="100%" @load="removeToolbar()" style="border: none; ">
                                    <p>Tu navegador no soporta la visualización de este archivo.</p>
                                </iframe> -->


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from './store';
import Security from './security';
import Swal from "sweetalert2";


export default {
    data() {
        return {
            uploadedFiles: {
                dni: [],
                fachada: [],
                beneficiario: [],
                expediente: [],
                otros: [],
            },
            showFiles: {
                dni: [],
                fachada: [],
                beneficiario: [],
                expediente: [],
                otros: [],
            },
            totalSize: 0,
            store,
            credito: {},
            ready: false,
            roles: ['Coordinacion', 'Admin', 'Finanzas', 'Asesor', 'Cordinador Sucursal', 'Consultor', 'gestorArchivos', 'oficinaGeneral'],
            Finanzas: false,
            Admin: false,
            Coordinacion: false,
            Asesor: false,
            coordinadorSucursal: false,
            gestorArchivos: false,
            oficinaGeneral: false,
            modoThanos: false,
            Consultor: false,
            readyArchivos: false,

            fileBase64: "",
            base64: "",
        };

    },
    components: {
    },

    beforeMount() {
        this.userRoles = Security.getUserRoles();
        // console.log(this.userRoles);
        this.roles.forEach(element => {

            if (this.userRoles[0]?.some(role => role.includes(element)) === true) {
                this[element] = true;
            } else if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
                this.coordinadorSucursal = true;
                // console.log('coordinador sucursal en true')
            } else if (this.userRoles[0]?.some(role => role.includes('Modo Thanos')) === true) {
                this.modoThanos = true;
                // console.log('Modo thanos en true')
            }
            if (this.userRoles[0]?.some(role => role.includes('Gestor Archivos')) === true) {
                this.gestorArchivos = true;
                // console.log('Modo thanos en true')
            }
            if (this.userRoles[0]?.some(role => role.includes('Oficina General')) === true) {
                this.oficinaGeneral = true;
                // console.log('Modo thanos en true')
            }
            /*       if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
                  } */


        });
        // Obtiene informacion de credito
        fetch(
            process.env.VUE_APP_API_URL +
            "/admin/credito-completo/" +
            this.$route.params.creditoId,
            Security.requestOptions("")
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    this.$emit("error", data.message);
                } else {
                    this.credito = data.data
                    // console.log(this.credito);
                    this.ready = true
                }
            });


        // Obtiene los Archivos
        this.fetchFiles();



    },

    methods: {
        removeToolbar() {
            const toolbar = document.getElementById('toolbar');
            if (toolbar) {
                toolbar.remove();
            }
        },
        // Valida que sea imagen el formato
        handleFileChange(event, fileType) {


            this.uploadedFiles[fileType].forEach(() => {


                this.showFiles[fileType].pop()

            });




            // console.log("File",);
            const files = Array.from(event.target.files);



            // console.log(files);
            if (['dni', 'fachada', 'beneficiario'].includes(fileType)) {
                const validFiles = files.filter(file => file.type.startsWith('image/'));
                if (validFiles.length === 0) {
                    alert('Por favor sube archivos en formato imagen (JPG, PNG, etc).');
                } else {
                    this.uploadedFiles[fileType] = validFiles;
                    files.forEach((validFiles) => {
                        this.showFiles[fileType].push(validFiles)
                    });
                }
            } else {
                // console.log(fileType);


                this.uploadedFiles[fileType] = files;



                files.forEach((file) => {

                    this.showFiles[fileType].push(file)
                });


            }


        },
        isImage(file) {
            return file.type.startsWith('image/');
        },
        createImagePreview(file) {
            return URL.createObjectURL(file);
        },
        esFormatoValidoParaEmbed(formato) {
            // Convertir el formato a minúsculas para evitar problemas de comparación
            formato = formato.toLowerCase();

            // Lista de formatos compatibles con <embed>
            const formatosValidos = ['.pdf', '.jpg', '.jpeg', '.png', '.gif'];

            // Verificar si el formato está en la lista de formatos válidos
            return formatosValidos.includes(formato);
        },
        getFileIcon(file) {
            const fileType = file.type;
            if (fileType.includes('pdf')) return 'fas fa-file-pdf';
            if (fileType.includes('word')) return 'fas fa-file-word';
            if (fileType.includes('excel')) return 'fas fa-file-excel';
            if (fileType.includes('audio')) return 'fas fa-file-audio';
            return 'fas fa-file-alt'; // Default icon
        },
        getFileFormat(file) {
            const fileType = file.type.split('/')[1].toUpperCase();
            return fileType || 'Desconocido';
        },
        deleteFile(file, fileType) {

            if (this.estaGuardado(file, fileType)) {
                // Encuentra el índice del objeto a eliminar
                const index = this.uploadedFiles[fileType].findIndex(obj => obj === file);
                const indexShow = this.showFiles[fileType].findIndex(obj => obj === file);
                this.uploadedFiles[fileType]

                if (index !== -1) {
                    // Elimina el objeto del array
                    this.uploadedFiles[fileType].splice(index, 1);
                    this.showFiles[fileType].splice(indexShow, 1);
                }
            } else if (this.Admin || this.gestorArchivos) {
                // console.log("Se llama el fetch para eliminar");

                let payload = {
                    slug: file.name
                }
                // console.log(payload)
                Swal.fire({
                    title: '¿Esta seguro que desea eliminar?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('confirmado')
                        fetch(process.env.VUE_APP_API_URL + `/admin/solicitud/archivo-delete`, Security.requestOptions(payload))
                            .then((response) => response.json())
                            .then((response) => {
                                if (response.error) {
                                    this.$emit('error', response.message);
                                    console.error(response.message)
                                } else {
                                    // console.log('Archivo Delete:', response)
                                    this.uploadedFilesEnCero()
                                    this.readyArchivos = false
                                    this.fetchFiles()
                                    // location.reload();
                                }
                            })
                            .catch(error => {
                                this.$emit('error', error)
                            })
                    }
                })
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Solicita elminar a tu administrador!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }

        },
        // mostrarArchivo(file) {
        //     console.log(file, this.archivos);

        //     for (let i = 0; i < this.archivos.length; i++) {
        //         if (this.archivos[i].slug === file.name) {

        //             if (this.esFormatoValidoParaEmbed(this.archivos[i].formato)) {
        //                 this.base64 = this.archivos[i]
        //             } else {
        //                 Swal.fire({
        //                     icon: "error",
        //                     title: "Formato de archivo no valido para previsualización!",
        //                     showConfirmButton: false,
        //                     timer: 2000,
        //                 });
        //             }
        //         }
        //     }


        // },
        limpiarURL() {
            if (this.base64.archivoBase64) {
                URL.revokeObjectURL(this.base64.archivoBase64);
                this.base64 = '';
            }
        },
        mostrarArchivo(file) {

            this.limpiarURL()
            // console.log(file, this.archivos);

            // Buscar el archivo por slug
            const archivoEncontrado = this.archivos.find(archivo => archivo.slug === file.name);

            if (archivoEncontrado) {
                // Verificar si el formato es válido para embed
                if (this.esFormatoValidoParaEmbed(archivoEncontrado.formato)) {
                    // Convertir Base64 a Blob
                    const byteCharacters = atob(archivoEncontrado.archivoBase64);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    // Crear Blob y URL
                    const blob = new Blob([byteArray], { type: this.generarMimeType(archivoEncontrado.formato) });
                    const url = URL.createObjectURL(blob);

                    // Asignar la URL en lugar de la cadena Base64
                    this.base64 = { ...archivoEncontrado, archivoBase64: url };

                    // console.log("Este base64 ahhh:", this.base64);
                    // Depuración: Imprimir URL del Blob
                    // console.log("Blob URL generado:", url);
                } else {
                    // Mostrar un mensaje si el formato no es válido
                    Swal.fire({
                        icon: "error",
                        title: "Formato de archivo no válido para previsualización!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }


            }
        },

        downloadFile(file) {
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            a.click();
            URL.revokeObjectURL(url);
        },
        // Subir Archivos
        async uploadFiles() {
            const formData = new FormData();

            // Agregar los archivos al FormData
            for (const [fileType, files] of Object.entries(this.uploadedFiles)) {
                files.forEach((file) => {
                    formData.append(`${fileType}`, file); // Asegúrate de que los archivos se están agregando correctamente
                });
            }

            // Agregar los IDs al FormData
            formData.append('idCredito', this.credito.id); // Asumiendo que tienes un ID de crédito en tu componente
            formData.append('idUsuario', this.store.user.id); // Asumiendo que tienes un ID de usuario en tu componente


            try {
                const response = await fetch(process.env.VUE_APP_API_URL + `/admin/solicitud/insertImg`, {
                    method: 'POST',
                    body: formData, // No uses JSON.stringify aquí, solo formData directamente
                    headers: {
                        Authorization: `Bearer ${store.token}`, // Añadir solo el encabezado de autorización
                    },
                });

                if (!response.ok) {
                    throw new Error('Error en la subida de archivos');
                }

                // const data = await response.json();
                Swal.fire({
                    icon: "success",
                    title: "Archivo Guardado Exitosamente!",
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.uploadedFilesEnCero()
                this.fetchFiles();
                // console.log(data);
            } catch (error) {
                alert('Hubo un error al subir los archivos.');
                console.error(error);
            }
        },
        async fetchFiles() {
            try {
                const response = await fetch(process.env.VUE_APP_API_URL + '/admin/solicitud/getArchivos/' + this.$route.params.creditoId, Security.requestOptions({}));
                if (!response.ok) {
                    throw new Error('Error al obtener archivos');
                }
                const formData = await response.json(); // Obtén el contenido como JSON
                this.parseFormData(formData); // Procesa los datos del formulario
            } catch (error) {
                console.error('Error:', error);
            }

            this.readyArchivos = true

        },
        parseFormData(formData) {
            // Asegúrate de que el formato de formData sea correcto
            this.totalSize = 0
            // console.log("El Form Data es:", formData.data.archivo);
            this.archivos = formData.data.archivo;

            for (let i = 0; i < this.archivos.length; i++) {
                // Convertir la cadena base64 en un File

                let mimeType = this.generarMimeType(this.archivos[i].formato);
                // console.log(mimeType);
                let base64String = `data:${mimeType};base64,${this.archivos[i].archivoBase64}`;

                let fileName = this.archivos[i].slug;

                // Convertir la cadena base64 en un File
                const file = this.base64ToFile(base64String, fileName, mimeType);
                // console.log(file);
                // console.log(Array.from(file));

                this.totalSize += file.size;

                switch (this.archivos[i].tipoArchivo) {
                    case 'dni':
                        this.showFiles["dni"].push(file)
                        break;
                    case 'fachada':
                        this.showFiles["fachada"].push(file)
                        break;
                    case 'beneficiario':
                        this.showFiles["beneficiario"].push(file)
                        break;
                    case 'expediente':
                        this.showFiles["expediente"].push(file)
                        // this.base64 = this.archivos[i].archivoBase64
                        break;
                    case 'otros':
                        this.showFiles["otros"].push(file)
                        break;

                }

            }

            // Convertir el total de bytes a MB o GB
            let totalSizeInMB = this.totalSize / (1024 * 1024); // Convertir de bytes a MB

            if (totalSizeInMB < 1024) {
                console.log(`El tamaño total de los archivos es: ${totalSizeInMB.toFixed(2)} MB`);
                this.totalSize = totalSizeInMB.toFixed(2) + "MB"
            } else {
                let totalSizeInGB = totalSizeInMB / 1024; // Convertir de MB a GB
                console.log(`El tamaño total de los archivos es: ${totalSizeInGB.toFixed(2)} GB`);
                this.totalSize = totalSizeInGB.toFixed(2) + "GB"
            }



        },
        generarMimeType(formato) {
            formato = formato.replace('.', ''); // Eliminar el punto
            let mimeType;

            // Intentar generar un MIME type genérico
            if (['pdf', 'zip', 'json', 'xml'].includes(formato)) {
                mimeType = `application/${formato}`;
            } else if (['jpg', 'jpeg', 'png'].includes(formato)) {
                mimeType = `image/${formato}`; // Para imágenes
            } else if (formato === 'txt') {
                mimeType = "text/plain"; // Para archivos de texto
            } else {
                mimeType = `application/${formato}`; // Para otros casos genéricos
            }

            return (mimeType); // Verificar el tipo MIME generado

        },
        base64ToFile(base64String, fileName, mimeType) {
            // Asegúrate de que base64String no tenga prefijo 'data:mime/type;base64,' si ya se ha eliminado
            // Si la cadena base64 incluye el prefijo, elimínalo primero
            const base64ContentArray = base64String.split(',');
            if (base64ContentArray.length !== 2) {
                throw new Error('Invalid Base64 string');
            }

            // Decodificar la parte que sigue a la coma
            const byteString = atob(base64ContentArray[1]);

            // Crear un array de bytes
            const byteNumbers = new Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                byteNumbers[i] = byteString.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            // Crear un Blob a partir de los datos binarios
            const blob = new Blob([byteArray], { type: mimeType });

            // Crear un objeto File a partir del Blob
            return new File([blob], fileName, { type: mimeType });
        },
        isImage2(file) {
            return file.endsWith('.png') || file.endsWith('.jpg') || file.endsWith('.jpeg');
        },
        estaGuardado(file, fileType) {
            if (this.uploadedFiles[fileType].includes(file)) {
                return true
            } else {
                return false
            }
        },
        uploadedFilesEnCero() {
            this.uploadedFiles = {
                dni: [],
                fachada: [],
                beneficiario: [],
                expediente: [],
                otros: []
            };
            this.showFiles = {
                dni: [],
                fachada: [],
                beneficiario: [],
                expediente: [],
                otros: []
            };
        }
    },
};
</script>

<style scoped>
.info-beneficiario {
    border-radius: 5px;
}

.subir-archivos {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #cad0dd09;
    border-radius: 5px;
}

.file-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.file-grid-item {
    width: 160px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    /* cursor: pointer; */
}

.file-preview-img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;

}

.file-icon {
    font-size: 50px;
    color: #007bff;
    margin-bottom: 5px;

}

/* Estilo para manejar nombres de archivo largos */
.file-grid-item p {
    font-size: 12px;
    margin: 0;
    padding: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.file-format {
    font-size: 10px;
    color: gray;
    margin-top: 5px;
}

.file-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}

.file-buttons button {
    width: 45px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.file-buttons i {
    font-size: 14px;
}

.btn-primary {
    color: white;
}

.visualizar {
    padding: 10px;
}
</style>
