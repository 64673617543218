<template>
    <div class="container-fluid">
        <div class="row mt-4">
            <div class="col-12 col-lg-4"></div>
            <div class="col-12 col-lg-4">
                <div class="text-center">
                    <b>BUSCA TU CRÉDITO POR:</b>
                    <p>NOMBRE DE BENEFICIARIO, DNI O NO.PRESTAMO</p>
                </div>

                <div class="d-flex flex-column flex-lg-row" role="search">

                    <input v-model="busqueda" class="form-control me-2" type="search" placeholder="Buscar Credito"
                        aria-label="Search">
                    <button class="btn btn-outline-success" @click="buscarCredito()" type="button">Buscar</button>

                </div>
            </div>
        </div>

        <div class="row mt-2">

            <TablaCreditos :key="`${busquedaEnviar}`" :busqueda="this.busquedaEnviar" />
        </div>


    </div>
</template>

<script>
import TablaCreditos from './TablaCreditos.vue';


export default {
    data() {
        return {
            busqueda: "",
            busquedaEnviar: "",
            ready: false,
        }
    },
    beforeMount(){
        if (this.busqueda === "") {
            this.busqueda = this.obtenerDeLocalStorage('busqueda')
            this.buscarCredito()
        }
    },
    components: {
        TablaCreditos,
    },
    methods: {
        buscarCredito() {
            this.busquedaEnviar = this.busqueda
        },
        obtenerDeLocalStorage(clave) {
            // Recuperamos la cadena JSON del localStorage
            const jsonString = localStorage.getItem(clave);

            // Verificamos si hay algo almacenado en la clave dada
            if (jsonString) {
                try {
                    // Convertimos la cadena JSON de vuelta a un array de objetos
                    const arrayDeObjetos = JSON.parse(jsonString);
                    console.log('Array recuperado:', arrayDeObjetos);
                    return arrayDeObjetos;
                } catch (error) {
                    console.error('Error al convertir el JSON:', error);
                    return null;
                }
            } else {
                console.warn(`No hay datos almacenados bajo la clave "${clave}"`);
                return null;
            }
        },

    }

}
</script>

<style scoped>
.btn {
    margin: 0;
}

.btn-outline-success {
    color: #00a632;
    border-color: #00a632;
}

.btn:hover {
    margin: 0;
    background-color: #00a632;
    color: white;
}
</style>