<template>
  <div class="container mt-4  horizontal-scrollable">
    <div class="row text-center d-flex justify-content-center">
      <div class="gestion">
        <h3 class="mt-3  display-4 notranslate ">GESTIÓN DE CARTERA</h3>

      </div>
    </div>
    <div class="row generar-buscar">


      <div class="col-12 col-lg-3">
        {{ this.estado }} , {{ this.sucursal }}
      </div>
      <div class="col-12 col-lg-3">
        <h4>Mostrar Por Sucursal</h4>
        <select v-if="this.Admin || this.coordinador" v-model="sucursal" id="sucursal" name="sucursal"
          class="form-select" required="true">

          <option v-for="(sucursal, index) in this.sucursales" :key="index" :value="sucursal">{{ sucursal }}</option>




        </select>
        <select v-else v-model="sucursal" id="sucursal" name="sucursal" class="form-select" required="true">
          <!-- <option>
            MOSTRAR TODAS
          </option> -->
          <option v-for="(sucursal, index) in this.sucursales" :key="index" :value="sucursal">{{ sucursal }}</option>



        </select>
      </div>

      <div class="col-12 col-lg-3">

        <h4>Mostrar Por Estado</h4>
        <select v-model="estado" id="estado" name="estado" class="form-select" required="true">
          <option>
            ACTIVOS
          </option>
          <option>
            LEGAL
          </option>
          <option>
            HISTORICO
          </option>
        </select>
      </div>
      <div class="col-12 col-lg-3">
        <CarteraApp :key="`${sucursal}-${estado}`" :sucursalFiltro="this.sucursal" :estadoFiltro="this.estado" />

      </div>

    </div>

    <!-- <CarteraApp :key="`${sucursal}-${estado}`" :sucursal="this.sucursal" :estado="this.estado" /> -->

    <div class="row">
      <buscador-credito />
    </div>
  </div>




</template>

<script>

import Security from "../security";
import BuscadorCredito from "./BuscadorCredito.vue";

//   import direccionData from '../solicitud/direccionData.json';
import CarteraApp from '@/components/cartera/Cartera-App';

export default {
  name: "Cartera-App",
  data() {
    return {
      sucursales: [],
      sucursal: '',
      roles: ['Coordinacion', 'Admin', 'Finanzas', 'Asesor', 'Cordinador Sucursal', 'Consultor'],
      Finanzas: false,
      Admin: false,
      Coordinacion: false,
      estado: "ACTIVOS",
      Asesor: false,
      coordinadorSucursal: false,
      modoThanos: false,
      Consultor: false,

      userRoles: []
    };
  },
  components: {
    CarteraApp,
    BuscadorCredito,
  },

  beforeMount() {
    this.sucursales = Security.getUserSucursal()[0];


    if (this.sucursales[0] === 'ALL') {
      this.sucursales[0] = 'MOSTRAR TODAS'

      let sucursales = ['FRANCISCO MORAZAN', 'EL PARAISO', 'ATLANTIDA', 'COPAN', 'LEMPIRA', 'OCOTEPEQUE', 'OLANCHO', 'SANTA BARBARA', 'COMAYAGUA AGRICOLA',
        'OLANCHO AGRICOLA', 'ATLANTIDA AGRICOLA', 'CHOLUTECA AGRICOLA', 'COLON AGRICOLA', 'EL PARAISO AGRICOLA', 'FRANCISCO MORAZAN AGRICOLA', 'INTIBUCA AGRICOLA', 'LEMPIRA AGRICOLA', 'LEMPIRA/TOMALA', 'LEMPIRA/ERANDIQUE', 'YORO AGRICOLA']

      this.sucursales = this.sucursales.concat(sucursales)
    } else {
      this.sucursales.unshift('MOSTRAR TODAS')
    }

    this.sucursal = this.sucursales[0]
    fetch(process.env.VUE_APP_API_URL + "/admin/credito/dashboard-data", Security.requestOptions({ sucursal: this.sucursal }))
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          this.$emit('error', response.message);

          console.error(response.message)
        } else {
          console.log('Dashboards Datos:', response.data)

          this.dashboard = response.data.dashboardData,
            this.ready = true;

        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
  },


  methods: {

  },

  watch: {



  }
};
</script>


<style>
.modal-footer .estado-verificado {
  width: 100%;
  text-align: center;
}

#Coordinacion,
#cordinadorSucursal {
  width: 100% !important;
}

.btn-behance {
  margin-left: 40px !important;
}

#tablaPlanPagos {
  >tr {
    >th {

      padding: 0.5rem;
    }

    >td {
      padding: 0.5rem;
    }
  }
}

#myTable_wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Tabla Cartera */
#myTable thead tr th {
  font-size: 0.8rem;
}

#myTable tbody tr td {
  font-size: 0.8rem;
  padding-block: 5px;
}

#myTable tbody tr td button {
  font-size: 0.8rem;
}

.dataTables_paginate {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;

  >a {
    border-radius: 5px;
    border: 1px solid #f8f8f8;
    background-color: #3a79ee;
    padding: 5px 10px;
    font-size: 0.9rem;
    color: #021a47;
    text-decoration: none;
    cursor: pointer;
  }

  >span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    >a {

      border-radius: 5px;
      border: 1px solid #b9b9b9;
      padding: 5px 10px;
      font-size: 0.9rem;
      color: #021a47;
      background-color: #f0f0f0;
      text-decoration: none;
      cursor: pointer;
    }
  }

  color: #021a47;
}

#myTable_filter {
  margin-left: 10px;
  width: 200px;
  float: flex-end;

  >label input[type="search"] {
    font-size: 0.9rem;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 10px;
    height: 30px;
    border: 1px solid #021a47;

  }
}

select[name="myTable_length"] {
  border: 1px solid #021a47;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  font-size: 0.9rem;
  text-align: center;
}

button {
  color: white;
}

.tb_custom {

  padding: 0;
  font-size: 0.85rem;
  width: 100%;
  margin: 0;
  border: none;

  >.trCustom_tb {
    padding: 0;
    margin: 0;
    text-align: left;

    >th {
      padding: 0;
      margin: 0;
      border: none;
    }

    >td {
      color: #233d6d;
      font-size: 0.9rem;
      padding: 0;
      margin: 0;
      border: none;
      text-align: right;
    }
  }
}

.resumenContent {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;

  >.resumenContent__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    margin: 0;
    padding: 0;

    >h4 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: #021a47;
    }

    >p {
      font-size: 0.9rem;
      font-weight: 400;
    }


  }


}

.btn-estado {
  margin-top: 0%;
  color: white;
}

.btn-estado:hover {
  color: rgb(232, 232, 232);
}

.btn-estado:active {
  color: rgb(232, 232, 232);
}

.btn-ver-pagos {
  margin-top: 0;
  margin-left: 60%;
  margin-right: 0.2%;
  color: white;
}

.btn-ver-pagos:hover {
  color: rgb(247, 245, 255)
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .btn-ver-pagos {
    margin-left: 0px;

    /* ajusta este valor según sea necesario */
  }
}

/* horizontal-scrollable>.row {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .horizontal-scrollable>.row>.col-xs-4 {
    display: inline-block;
    float: none;
  } */

.horizontal-scrollable {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable .col-xs-4 {
  display: inline-block;
  float: none;
  vertical-align: top;
  /* Añade esto para corregir problemas de alineación */
}

/* Decorations */

.col-xs-4 {
  color: white;
  font-size: 24px;
  padding-bottom: 20px;
  padding-top: 18px;
}

.col-xs-4:nth-child(2n+1) {
  background: green;
}

.col-xs-4:nth-child(2n+2) {
  background: black;
}

.btn-am {
  height: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0px;
}

/* .ver-mas-right {} */

.total-pagado {
  /* height: 300px ; */
  background-color: #3a79ee;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  /* justify-content: center; */
  padding: 7%;

}

.pagado {
  width: 100%;
}

.pagado h4 {
  font-size: 13px;
}

.pagado p {
  font-size: 12px;
  font-weight: 300;
}

.btn-volver {
  background-color: #3a79ee;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 0.9rem;
  font-weight: 300;
  width: 60px;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
}

.registro-pago {
  border-top: 3px solid #ff910088;
  border-right: 3px solid #4286f467;
  padding: 10px;
  padding-top: 25px;
  border-radius: 15px;
}

.modal-footer {
  padding: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.generar-buscar {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* gap: 10px; */
  width: 100%;
  background-color: #d5d5d5;
  margin: 0px 15px 15px 15px;
  padding: 10px;
  border-radius: 15px;
  color: white;
  width: 95%;
}

.imagen-recibo {
  width: 80%;
  margin-top: 5px
}

.importante-pagar {
  border-radius: 30px;
  border: 1px solid rgb(160, 160, 160);
  padding-block: 7px;
  padding-inline: 15px;
}

.importante-pagar:hover {

  border: 1px solid rgb(218, 189, 189);


}
</style>

<style scoped>
/* @media (min-width: 1200px){
      .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 100%;
      }
    } */
/* Estilos base para el contenedor */
.contenedor {
  position: relative;
  overflow: hidden;
  /* width: 200px; */
  /* height: 200px; */
}

/* Estilos para la imagen dentro del contenedor */
.contenedor img {

  transition: transform 0.5s ease-in-out;
}

/* Estilos para la imagen cuando se pasa el mouse sobre ella */
.contenedor:hover img {

  border-radius: 0;
  width: 80%;
  height: 80%;
  /* transform: scale(1.2); Cambia el valor según sea necesario para el nivel de zoom deseado */
}

.titulo {
  font-size: 12px;
}

.contenido-ver {
  font-size: 12px;
  margin-bottom: 0;
}

.cont-ver {
  border-radius: 12px;
  padding-block: 15px;
  margin-bottom: 20px;
  background-color: white;

  /* Aplicar drop-shadow solo para pantallas anchas */
  @media (min-width: 768px) {
    filter: drop-shadow(5px 5px 7px #0000001a);
  }
}

.cont-ver:hover {
  /* transition: cubic-bezier(0.075, 0.82, 0.165, 1); */
  /* border: 0.5px solid rgb(255, 185, 185); */

}

.cont-ver:hover hr {
  color: rgb(253, 132, 132);
  /* border: 0.5px solid rgb(255, 185, 185); */

}

@media (min-width: 992px) {

  /* .container, .container-sm, .container-md, .container-lg {
      max-width: 80%;
  } */
  .horizontal-scrollable {
    max-width: 80%;
  }
}

.pagos-text {
  font-size: 12px;
  padding: 7px;
  margin-bottom: 0;
}

.pagos-text-nombre {
  font-size: 14px;
  padding: 5px;
  margin-bottom: 0;
}


.form-control {
  height: 30px;
  font-size: 12px;
}

.form-select {
  height: 30px;
  font-size: 12px;
}

.img-recibo {
  height: 40px !important;
}

.gestion{
  /* background-color: #00a971; */
  background-color: #00a632;
  color: white;
  width: 40%;
  border-radius: 15px 15px 0px 0px;
}
</style>