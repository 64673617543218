<template>
    <div class="contenedor">
        <h1>Edición Modo Super Usuario</h1>
        <div class="info w-100 m-5">
            <h4>{{ this.credito?.cliente.nombres }}</h4>
            <p>
                DNI: {{ this.credito?.cliente.dni }} <br>
                Telefono: {{ this.credito?.cliente.telefono }} <br>
            </p>
        </div>
        <button class="btn btn-danger" @click="handleSubmit">Actualizar Datos</button>
        <div class="solicitudContainer">
            <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwoMore">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwoMore" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwoMore">
                        <div class="col-6">
                            Condiciones del Credito
                        </div>
                        <div id="validar-2" class="d-flex justify-content-end me-3" style="width: 100%">
                            {{ this.solicitud.producto }}
                        </div>
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseTwoMore" class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwoMore">
                    <div class="accordion-body">

                        <!-- Plan pagos -->
                        <div id="contenedor-plan">
                            <div class="header">
                                <h3>Solicitud de préstamo</h3>
                            </div>
                            <div name="frmPrestamo" id="frmPrestamo">
                                <div class="control">
                                    <label for="monto">Monto Aprobado: </label>
                                    <input type="number" required="true" class="form-control" v-model="solicitud.monto"
                                        name="monto" id="monto" placeholder="monto" min="500" max="300000">
                                </div>

                                <div class="control">
                                    <label for="periodo">Periodo de pago:</label>
                                    {{ solicitud.frecuenciaPago }}
                                    <select v-model="solicitud.frecuenciaPago" required="true" class="form-control"
                                        name="periodo" id="periodo">
                                        <!-- <option :value="solicitud.frecuenciaPago">{{solicitud.frecuenciaPago}}</option> -->
                                        <option value="semanal">semanal</option>
                                        <option value="quincenal">quincenal</option>
                                        <option value="mensual">mensual</option>
                                        <option value="al vencimiento">Al Vencimiento</option>
                                        <option value="trimestral">trimestral</option>
                                        <option value="semestral">semestral</option>
                                        <option value="anual">anual</option>
                                    </select>
                                </div>
                                <div class="control">
                                    <label for="interes">Interés (anual): </label>
                                    <input type="number" class="form-control" v-model="solicitud.interes"
                                        required="true" name="interes" id="interes" placeholder="interés" min="5"
                                        max="100" step=".01">
                                </div>
                                <div class="control">
                                    <label for="interes">Tasa Mora (anual): </label>
                                    <input type="number" class="form-control" v-model="solicitud.tasaMora"
                                        required="true" name="interes" id="interes" placeholder="Tasa Mora" min="5"
                                        max="100" step=".01">
                                </div>
                                <div class="control">
                                    <label for="plazo">Plazo: (Mensual) </label>
                                    <input type="number" class="form-control" placeholder="Plazo Pago" required="true"
                                        name="plazo" v-model="solicitud.plazoPago" id="plazo" min="1" step="1">

                                </div>
                                <label for="plazo">Producto: </label>

                                <select v-model="solicitud.producto" class="form-select"
                                    aria-label="Default select example">
                                    <option selected :value="this.solicitud.producto">{{ this.solicitud.producto }}
                                    </option>
                                    <option value="Escalonado">Escalonado</option>
                                    <option value="MIPYME">MIPYME</option>
                                    <option value="Crédito Agrícola">Crédito Agrícola</option>
                                    <option value="Cadenas Productivas">CADENAS PRODUCTIVAS</option>
                                    <option value="Cajas Rurales">CAJAS RURALES , COOPERATIVAS O EMPRESAS DEL SECTOR
                                        SOCIAL DE LA ECONOMIA</option>
                                    <option value="Credito Salud">CRÉDITO SALUD</option>
                                    <option value="Credimur">CREDIMUR</option>
                                    <option value="Prestamo Navideño">PRESTAMO NAVIDEÑO</option>
                                    <option value="Credi Trabajo">CREDI TRABAJO</option>
                                    <option value="Credi Impulsa">CREDI IMPULSA</option>
                                </select>


                            </div>
                        </div>

                        <!-- Modal -->

                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-scrollable modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Plan de Pagos</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div id="contenedorTabla">
                                            <div class="header">
                                                <h2>Tabla de amortizaciones</h2>
                                            </div>
                                            <table class="table table-striped table-hover">

                                                <thead class="table-dark">
                                                    <tr>
                                                        <th scope="col">No. Cuota</th>
                                                        <th scope="col">Fecha de Pago</th>
                                                        <th scope="col">Cuota Total</th>
                                                        <th scope="col">Interés</th>
                                                        <th scope="col">Capital</th>
                                                        <th scope="col">Saldo</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="tablaPlanPagos">

                                                </tbody>
                                            </table>
                                            <div id="amortizaciones">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-bs-dismiss="modal">Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="solicitudContainer">
            <div class="accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwoMore">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panellCredito" aria-expanded="true" aria-controls="panellCredito">
                        <div class="col-6">
                            Credito
                        </div>
                        <div id="validar-2" class="d-flex justify-content-end me-3" style="width: 100%">
                        </div>
                    </button>
                </h2>
                <div id="panellCredito" class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwoMore">
                    <div class="accordion-body">

                        <!-- Plan pagos -->
                        <div id="contenedor-plan">
                            <div class="header">
                                <h3>Crédito</h3>


                            </div>
                            <div name="frmPrestamo" id="frmPrestamo">
                                <div class="control">
                                    <label for="estado">Estado del Credito:</label>
                                    <select v-model="credito.estado" required="true" class="form-control" name="estado"
                                        id="periodo">
                                        <option value="Activo">Activo</option>
                                        <option value="Cancelado">Cancelado</option>
                                        <option value="Vencido">Vencido</option>
                                    </select>
                                </div>
                                <div class="control">
                                    <label for="noPrestamo">No Prestamo: </label>
                                    <input type="text" required="true" class="form-control" v-model="credito.noPrestamo"
                                        name="noPrestamo" id="noPrest" placeholder="no Prestamo">
                                </div>
                                <div class="control">
                                    <label for="fechaDesembolso">Fecha Desembolso:</label>
                                    <input type="date" required="true" class="form-control mt-2 mb-2"
                                        v-model="credito.fechaDesembolso" name="fechaDesembolso" id="fecha"
                                        placeholder="fecha">
                                </div>
                                <div class="control">
                                    <label for="FechaUltimaPago">Fecha Ultimo Pago: </label>
                                    <input type="date" required="true" class="form-control mt-2 mb-2"
                                        v-model="credito.fechaUltimaPago" name="FechaUltimaPago" id="fecha"
                                        placeholder="fecha">
                                </div>
                                <div class="control">
                                    <label for="fechaVencimiento">Fecha Vencimiento: </label>
                                    <input type="date" required="true" class="form-control mt-2 mb-2"
                                        v-model="credito.fechaVencimiento" name="fechaVencimiento" id="fecha"
                                        placeholder="fecha">
                                </div>

                                <div class="control">
                                    <label for="montoAprobado">Monto Aprobado: </label>
                                    <input type="number" required="true" class="form-control"
                                        v-model="credito.montoDesembolsado" name="montoAprobado" id="monto"
                                        placeholder="monto" min="500" max="300000">
                                </div>

                                <div class="control">
                                    <label for="TotalPagado">Total Pagado: </label>
                                    <input type="number" required="true" class="form-control"
                                        v-model="credito.totalPagado" name="TotalPagado" id="monto" placeholder="monto"
                                        min="500" max="300000">
                                </div>

                                <div class="control">
                                    <label for="">Capital Pagado: </label>
                                    <input type="number" required="true" class="form-control"
                                        v-model="credito.capitalPagado" name="CapitalPagado" id="monto"
                                        placeholder="monto" min="500" max="300000">
                                </div>
                                <div class="control">
                                    <label for="">Capital Acumulado: </label>
                                    <input type="number" required="true" class="form-control"
                                        v-model="credito.capitalAcumulado" name="CapitalAcumulado" id="monto"
                                        placeholder="monto" min="500" max="300000">
                                </div>
                                <div class="control">
                                    <label for="">Total Interes Pagado: </label>
                                    <input type="number" required="true" class="form-control"
                                        v-model="credito.totalInteresPagado" name="TotalInteresPagado" id="monto"
                                        placeholder="monto" min="500" max="300000">
                                </div>
                                <div class="control">
                                    <label for="">Total Mora Pagada: </label>
                                    <input type="number" required="true" class="form-control"
                                        v-model="credito.totalMoraPagada" name="TotalMoraPagada" id="monto"
                                        placeholder="monto" min="500" max="300000">
                                </div>
                                <div class="control">
                                    <label for="">Saldo: </label>
                                    <input type="number" required="true" class="form-control" v-model="credito.saldo"
                                        name="Saldo" id="monto" placeholder="monto" min="500" max="300000">
                                </div>

                                <div class="control">
                                    <label for="">Es localizable: </label>
                                    <div class="form-check  ms-5">
                                        <input v-model="this.credito.esLocalizable" class="form-check-input"
                                            type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="true"
                                            checked>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Es Localizable
                                        </label>
                                    </div>
                                    <div class="form-check  ms-5">
                                        <input v-model="this.credito.esLocalizable" class="form-check-input"
                                            type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="false">
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            No es Localizable
                                        </label>
                                    </div>
                                </div>
                                <div class="control">
                                    <label for="">Selecciona el proceso en el que se encuentra: </label>
                                    <select v-model="this.credito.estadoProceso" class="form-select"
                                        aria-label="Default select example">
                                        <option value="EXTRAJUDICIAL">Extrajudicial</option>
                                        <option value="JUDICIAL/LEGAL">Judicial/Legal</option>
                                        <option value="CASTIGO">Castigo/cartera(Ilocalizables y Fallecidos)</option>
                                        <option value="IRREGULAR">Irregular</option>
                                        <option value="NORMAL">Normal</option>
                                    </select>
                                </div>




                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Security from '../security';
import { solicitudCompleta } from "../solicitud";
import router from '../../router/index.js'
export default {
    name: 'SoliCredito',
    data() {
        return {

            departamentos: [],
            departamentoSeleccionado: null,
            planPagoToPrint: [],
            comisionDesembolso: 2.50,
            totalComisionDesembolso: 0.00,
            comisionAsistenciaTecnica: 3.50,
            totalComisionAsistenciaTecnica: 0.0,
            tasaSeguridad: 0.40,
            totalTasaSeguridad: 0.0,
            montoNeto: 0,
            idSolicitud: 0,
            validarAcordion: "",
            solicitudCompleta,
            fechaNacimiento: "",
            edadsoli: "",
            estadoCivil: "",
            final: false,
            noReferencias: 1,
            dni: "",
            cliente: {},
            gruposSolidariosArray: [],
            credito: {
                id: 0,
                idCredito: 0,
                estado: 0,
                noPrestamo: 0,
                fechaDesembolso: '',
                fechaVencimiento: '',
                montoDesembolsado: 0,
                montoDesembolsadoReal: 0,
                valorCuota: 0,
                totalCuotasPagar: 0,
                capitalIntereses: 0,
                totalPagado: 0,
                fechaUltimaCuotaPagada: 0,
                noCuotasPagadas: 0,
                cuotasAlDia: 0,
                cuotasPagadas: 0,
                interesAtrasado: 0,
                diasPeriodo: 0,
                noCuotas: 0,
                diasInteres: 0,
                diasMora: 0,
                fechaCuotaSinPagar: '',
                interesPorPagar: 0,
                saldoInteresPorPagar: 0,
                moraPorPagar: 0,
                saldoMoraPorPagar: 0,
                capitalDebePagar: 0,
                minimoDebePagarCuota: 0,
                primerPagoMora: 0,
                totalMontoCancelar: 0,
                capitalPagado: 0,
                capitalAcumulado: 0,
                capitalDeberiaAcumulado: 0,
                saldoAnterior: 0,
                saldo: 0,
                totalInteresPagado: 0,
                totalMoraPagada: 0,
                fechaUltimaPago: '',
                idSolicitud: 0,
                solicitud: {},
                hoy: '',
                registroPago: 0,
                totalPagarCancelar: 0,
                clienteID: 0,
                cliente: {},
                createdAt: '',
                updatedAt: '',
                esLocalizable: true,
                estadoProceso: "",

            },
            solicitud: {
                id: 0,
                clienteId: 0,
                producto: "",
                destinoCreditos: [],
                tiempoVivienda: "",
                materialVivienda: "",
                noPersonasVivivenda: "",
                noFamilias: "",
                noTrabajan: "",
                serviciosPublico: [],
                noDependientes: "",
                noBuscanEmpleo: "",
                planInversion: {
                    req1: "",
                    req2: "",
                    req3: "",
                    req4: "",
                    req5: "",
                    desc1: "",
                    desc2: "",
                    desc3: "",
                    desc4: "",
                    desc5: "",
                    val1: 0,
                    val2: 0,
                    val3: 0,
                    val4: 0,
                    val5: 0,
                },
                referencias: {
                    nom1: "",
                    nom2: "",
                    nom3: "",
                    nom4: "",
                    par1: "",
                    par2: "",
                    par3: "",
                    par4: "",
                    dir1: "",
                    dir2: "",
                    dir3: "",
                    dir4: "",
                    cel1: 0,
                    cel2: 0,
                    cel3: 0,
                    cel4: 0,
                },
                sector: [],
                gruposEspeciales: [],
                idGrupoSolidario: 0,

                fechaPago: "",
                monto: '',
                frecuenciaPago: "",
                interes: 12,
                tasaMora: 0,
                tipoPlazo: "mensual",
                plazoPago: '',
                garantia: '',
                estado: '',
                sucursal: "",
                
            },
            userRoles: [],
            Asesor: false,
            estadoProcesoBool: false,
        }
    },
    beforeMount() {
        this.idSolicitud = this.$route.params.id
        if (this.idSolicitud > 0) {
            //editing a solicitante
            fetch(
                process.env.VUE_APP_API_URL +
                "/admin/credito-completo/" +
                this.$route.params.id,
                Security.requestOptions("")
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        this.$emit("error", data.message);
                    } else {
                        // this.Grupo = data.data;
                        let currentSol = data.data
                        this.gruposSolidariosArray = data.data
                        this.credito = currentSol
                        this.solicitud.fechaPago = currentSol.fechaPago
                        this.solicitud.interes = currentSol.solicitud.interes
                        this.solicitud.frecuenciaPago = currentSol.solicitud.frecuenciaPago.toLowerCase()
                        this.cliente = currentSol.cliente
                        // solicitudCompleta.nombreGrupoSolidario = data.data.nombreGrupoSolidario
                        // solicitudCompleta.integrantes = data.data.clientes
                        // this.dni = data.data.dni;
                        // this.cliente.estado = data.data.estado;
                        this.comisionAsistenciaTecnica = 3.50
                        this.comisionDesembolso = 2.50
                        this.tasaSeguridad = 0.40
                        this.totalComisionDesembolso = this.solicitud.monto * (this.comisionDesembolso / 100)
                        this.totalComisionAsistenciaTecnica = this.solicitud.monto * (this.comisionAsistenciaTecnica / 100)
                        this.totalTasaSeguridad = this.solicitud.monto * (this.tasaSeguridad / 100)
                        this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad
                        this.credito.fechaUltimaPago = this.dateFormat(currentSol.fechaUltimaPago)
                        this.credito.fechaDesembolso = this.dateFormat(currentSol.fechaDesembolso)
                        this.credito.fechaVencimiento = this.dateFormat(currentSol.fechaVencimiento)
                        this.credito.fechaUltimaCuotaPagada = this.dateFormat(currentSol.fechaUltimaCuotaPagada)
                        this.credito.estado = ((currentSol.estado.toLowerCase()).charAt(0).toUpperCase() + (currentSol.estado.toLowerCase()).slice(1)).trim()
                        this.solicitud.plazoPago = parseInt(currentSol.solicitud.plazoPago)
                        this.solicitud.producto = currentSol.solicitud.producto
                        this.solicitud.monto = parseFloat(this.credito.montoDesembolsado)
                        this.solicitud.id = currentSol.solicitud.id
                        this.solicitud.tasaMora = currentSol.solicitud.tasaMora

                        this.credito.esLocalizable = currentSol.esLocalizable,
                        this.credito.estadoProceso = currentSol.estadoProceso,

                        
                            console.log(currentSol)
                    }
                    if (this.credito.estadoProceso) {
                            this.estadoProcesoBool = true
                        }
                });
        }
    },
    methods: {
        dateFormat(date) {
            let dateObj = date.slice(0, 10)
            return dateObj;
        },
        fechaToTimeStamp(date) {
            let fecha = new Date(date)
            fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
            return fecha.toISOString()
        },
        handleSubmit() {
            let fecha = new Date(this.credito.fechaDesembolso)
            fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
            console.log(fecha)

            const payload = {
                solicitud: {
                    ...this.solicitud,
                    fechaPago: '',
                },
                credito: {
                    ...this.credito,
                    fechaDesembolso: '',
                    fechaVencimiento: '',
                    fechaUltimaCuotaPagada: '',
                    fechaUltimaPago: '',
                },
                fechaDesembolso: this.credito.fechaDesembolso,
                fechaVencimiento: this.credito.fechaVencimiento,
                fechaUltimaCuotaPagada: this.credito.fechaUltimaCuotaPagada,
                fechaUltimaPago: this.credito.fechaUltimaPago,
                fechaPago: this.credito.fechaDesembolso,
            }
            delete payload.solicitud.fechaPago
            delete payload.credito.fechaDesembolso
            delete payload.credito.fechaVencimiento
            delete payload.credito.fechaUltimaCuotaPagada
            delete payload.credito.fechaUltimaPago

            console.log(payload)
            Swal.fire({
                title: '¿Estás Seguro que desea Modificar el credito?',
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('confirmado')
                    fetch(
                        process.env.VUE_APP_API_URL +
                        "/admin/credito/update-thanos-snap",
                        Security.requestOptions(payload)
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                Swal.fire({
                                    // position: 'top-end',
                                    icon: "error",
                                    title: data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            } else {
                                console.log(data.data);
                                router.push('/Cartera')
                            }
                        });
                }
            })

        }
    },

    ejecutarPlanPagos() {
        const tablaBody = document.getElementById('tablaPlanPagos')
        console.log(this.solicitud.fechaPago, this.solicitud.monto, this.solicitud.frecuenciaPago, this.solicitud.interes, this.solicitud.plazoPago)
        if (this.solicitud.fechaPago === '' || this.monto === '' || this.solicitud.frecuenciaPago === '' || this.solicitud.interes === '' || this.solicitud.plazoPago === '') {
            tablaBody.innerHTML = `
          <tr>
            <td colspan=6 class="text-center text-danger">
              <h3>
                ¡Faltan datos!</td>  
              </h3>
          </tr>
        `
        } else {
            this.generarPlanPago(this.stringToDate(this.credito.fechaDesembolso), this.solicitud.monto, this.solicitud.frecuenciaPago, this.solicitud.interes, this.solicitud.plazoPago)
        }
    },
    generarPlanPago(
        fechaApertura,
        monto,
        periodoPago,
        interesAnual,
        plazo
    ) {
        let fechaInicial = fechaApertura
        // Calcular la tasa de interés mensual
        let tasaAnual = interesAnual / 100;
        console.log("tasa anual:", tasaAnual);

        // Calcular el intervalo de tiempo entre pagos
        let duracionPago, periodo, noCuotas, interes, diasPeriodo, capital, saldo;

        // Imprimir la cabecera de la tabla
        console.log(
            "No. Cuota",
            "Fecha de Pago",
            "Cuota Total",
            "Interés",
            "Capital",
            "Saldo"
        );
        // Generar el plan de pago
        saldo = monto;
        console.log(
            0,
            fechaApertura.toISOString().slice(0, 10),
            0.0,
            0.0,
            0.0,
            saldo
        );

        switch (periodoPago) {
            case "mensual":
                duracionPago = 30 * 24 * 60 * 60 * 1000;
                fechaApertura = new Date(
                    fechaApertura.getTime() + 30 * 24 * 60 * 60 * 1000
                );
                noCuotas = plazo;
                periodo = 12;
                diasPeriodo = 30.0;
                break;

            case "quincenal":
                duracionPago = 14 * 24 * 60 * 60 * 1000;
                fechaApertura = new Date(
                    fechaApertura.getTime() + 15 * 24 * 60 * 60 * 1000
                );
                noCuotas = plazo * 2;
                periodo = 24;
                diasPeriodo = 15.0;
                break;

            case "semanal":
                duracionPago = 7 * 24 * 60 * 60 * 1000;
                fechaApertura = new Date(
                    fechaApertura.getTime() + 7 * 24 * 60 * 60 * 1000
                );
                noCuotas = plazo * 4.3333333333;
                periodo = 52.1;
                diasPeriodo = 7.0;
                break;

            case "alvencimiento":
                duracionPago = 1;
                fechaApertura = new Date(
                    fechaApertura.getTime() + 7 * 24 * 60 * 60 * 1000
                );
                noCuotas = 1;
                periodo = 1;
                diasPeriodo = 1;
                break;

            default:
                console.log("Periodo de pago no válido");
                return;
        }

        // Generar el plan de pago
        let cuotaTotal = this.CalculaCuotaNivelada(
            monto,
            tasaAnual,
            plazo,
            periodo,
            diasPeriodo
        );
        this.planPagoToPrint = []
        const tablaBody = document.getElementById('tablaPlanPagos')
        tablaBody.innerHTML = ''
        tablaBody.innerHTML = `
        <tr>
          <th class="text-center align-middle" scope="row">0</td>
          <td class="text-center align-middle">${fechaInicial.toISOString().slice(0, 10)}</td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
        </tr>
      `
        this.planPagoToPrint.push({
            noCuota: 0,
            fechaPago: fechaInicial.toISOString().slice(0, 10),
            cuotaTotal: '',
            interes: '',
            capital: '',
            saldo: saldo.toFixed(2)
        })
        // console.log('PRODUCTO: ', this.solicitudCompleta.producto)
        if (this.solicitudCompleta.producto !== 'Credi Trabajo') {
            for (let i = 1; i <= Math.round(noCuotas); i++) {
                interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
                capital = cuotaTotal - interes;
                saldo = saldo - capital;
                // Imprimir la fila de la tabla
                tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${fechaApertura.toISOString().slice(0, 10)}</td>
            <td class="text-center align-middle">${Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            <td class="text-center align-middle">${Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            <td class="text-center align-middle">${Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
          `
                this.planPagoToPrint.push({
                    noCuota: i,
                    fechaPago: fechaApertura.toISOString().slice(0, 10),
                    cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    interes: Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    saldo: Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                })
                fechaApertura = new Date(fechaApertura.getTime() + duracionPago);

            }
        } else {
            cuotaTotal = monto / noCuotas;
            for (let i = 1; i <= Math.round(noCuotas); i++) {
                interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
                capital = cuotaTotal;
                saldo = saldo - capital;
                // Imprimir la fila de la tabla
                tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${fechaApertura.toISOString().slice(0, 10)}</td>
            <td class="text-center align-middle">${cuotaTotal.toFixed(2)}</td>
            <td class="text-center align-middle"></td>
            <td class="text-center align-middle">${capital.toFixed(2)}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            `
                this.planPagoToPrint.push({
                    noCuota: i,
                    fechaPago: fechaApertura.toISOString().slice(0, 10),
                    cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    interes: '',
                    capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    saldo: Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                })
                fechaApertura = new Date(fechaApertura.getTime() + duracionPago);

            }
        }

    }
    ,
    CalculaCuotaNivelada(
        montoPrestamo,
        tasaInteres,
        periodos,
        periodo,
        diasPeriodo
    ) {
        if (periodo === 24) {
            periodos *= 2;
        } else if (periodo === 52.1) {
            periodos *= 4.33;
            periodos = Math.round(periodos);
        }

        let tasaMensual = (tasaInteres / 360) * diasPeriodo;
        tasaMensual = Math.round(tasaMensual * 10000000000) / 10000000000;

        let cuota =
            (montoPrestamo * tasaMensual) / (1 - Math.pow(1 + tasaMensual, -periodos));
        return cuota;
    },


    stringToDate(dateString) {
        // Cadena con formato mm/dd/yyyy
        // Diseño que coincide con el formato de la cadena
        let date = new Date(dateString.split("/").reverse().join("-"));
        return date;
    },
    watch: {
        'credito.esLocalizable'(newValue) {
            // Convierte el valor a booleano
            if (newValue === 'true') {
                this.credito.esLocalizable = true;
            } else if (newValue === 'false') {
                this.credito.esLocalizable = false;
            }
        }
    }
}

</script>

<style scoped>
/* Estilos específicos del componente */
.contenedor h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 25px;
    margin-top: 20px;
}

.info {
    padding-left: 40px;
    margin: 0 !important;

}

.contenedor {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.solicitudContainer {
    flex: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    >.accordion-item {
        width: 90% !important;
    }
}

#contenedor-plan {
    padding: 15px;

    .header h3 {
        text-align: center;
    }

    #frmPrestamo {
        display: flex !important;
        flex-direction: column;

        gap: 20px !important;
    }
}

.btn-danger {
    margin-bottom: 10px;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    font-size: 1.25rem;

}

select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}
</style>
