<template>

    <div class="container mt-5">
        <h2 class="text-center">Simular Pagos</h2>
        <div>
            <p>{{  }}</p>
        </div>
        

        <div style="overflow-x: auto; overflow-y: auto; width: 100%;" >
            <!-- <table border="1" cellspacing="0" cellpadding="10" class="table"> -->
            <table  class="table table-striped" >
                <thead>
                    <tr>
                        <th>Pago</th>
                        <th class="table-info">Monto</th>
                        <!-- <th>Fecha Pago</th> -->
                        <th>Fecha Pago</th>
                        <th>Días de Mora</th>
                        <th>Mora Pagada</th>
                        <th>No Cuotas</th>
                        <th>Fecha Cuota s/p</th>
                        <th>Pago al día</th>
                        <th>Capital Atrasado</th>
                        <th>Capital Deberia Acumulado</th>
                        <th class="table-info">Total Capital Acumulado</th>
                        <th class="table-info">Total Interés Pagado</th>
                        <th class="table-info">Total Mora Pagada</th>
                        <th class="table-info">Total Pagado</th>
                        <th>Saldo</th>
                    </tr>
                </thead>
                <tbody class="table-group-divider">
                    <tr v-for="(pago, index) in pagos" :key="index">
                        <td>{{ index }}</td>
                        <td class="table-secondary">{{ formatoNumero(pagosMonto[index - 1] ? pagosMonto[index - 1].montoPagado : 0) }} {{
                            this.moneda }}</td>
                        <!-- <td>{{ pagosMonto[index] ? pagosMonto[index].fechaPagado : 0 }}</td> -->
                        <td>{{ this.dateFormat(pago.fechaUltimaPago) }} </td>
                        <td>{{ pago.diasMora }}</td>
                        <td>{{ formatoNumero(pago.moraPorPagar) }} {{ this.moneda }}</td>
                        <td>{{ pago.noCuotas-1  }}</td>
                        <td>{{ this.dateFormat(pago.fechaCuotaSinPagar)  }}</td>
                        <td>{{ formatoNumero(pago.minimoDebePagar) }} {{ this.moneda }}</td>
                        <td>{{ formatoNumero(pago.capitalDebePagar) }} {{ this.moneda }}</td>
                        <td>{{ formatoNumero(pago.capitalDeberiaAcumulado) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.capitalAcumulado) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.totalInteresPagado) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.totalMoraPagada) }} {{ this.moneda }}</td>
                        <td class="table-secondary">{{ formatoNumero(pago.totalPagado) }} {{ this.moneda }}</td>
                        <td>{{ formatoNumero(pago.saldo) }} {{ this.moneda }}</td>
                    </tr>
                    <!-- Fila de totales -->
                    <tr>
                        <td class=""><strong>Total</strong></td>
                        <td class="">{{ formatoNumero(totales.monto) }} {{ this.moneda }} </td>

                    </tr>
                </tbody>
            </table>
        </div>




    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Security from '../security';

export default {
    data() {
        return {
            creditoId: 0,
            pagos: [],
            pagosMonto: [],
            moneda: "lps"
        }
    },
    beforeMount() {
        this.idCredito = this.$route.params.creditoId

        let payload = {
            idCredito: parseInt(this.idCredito, 10)
        }

        fetch(process.env.VUE_APP_API_URL + `/admin/pagos/simular-pagos`, Security.requestOptions(payload))
            .then((response) => response.json())
            .then((response) => {
                if (response.error) {
                    this.$emit('error', response.message);
                    console.error(response.message)
                } else {
                    console.log('Simulador de pagos:', response)
                    this.pagos = response.data

                }
            })
            .catch(error => {
                this.$emit('error', error)
            })

        let payload2 = {
            idCredito: parseInt(this.idCredito, 10)
        }
        fetch(process.env.VUE_APP_API_URL + `/admin/pagos/allById`, Security.requestOptions(payload2))
            .then((response) => response.json())
            .then((response) => {
                if (response.error) {
                    Swal.fire({
                        position: 'top-end',
                        icon: "error",
                        title: response.error,
                        showConfirmButton: false,
                        timer: 1500,
                    });

                } else {

                    // this.pagos = response.data.pagos.reverse();
                    this.pagosMonto = response.data.pagos.reverse();
                    console.log('Pagos Hecho:', this.pagosMonto);
                }
            })
            .catch(error => {
                this.$emit('error', error);
            });
    },
    methods: {
        dateFormat(param) {

            var fechaISO = param;

            // Crear un objeto Date con la fecha proporcionada
            var fecha = new Date(fechaISO);

            // Obtener el día del mes (agregando un cero inicial si es necesario)
            var dia = ('0' + fecha.getUTCDate()).slice(-2);

            // Obtener el mes (agregando un cero inicial si es necesario)
            var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

            // Obtener el año
            var anio = fecha.getUTCFullYear();

            // Formatear la fecha según tus necesidades
            var fechaFormateada = dia + '/' + mes + '/' + anio;

            // console.log(fechaFormateada); // Salida: 01/12/2023

            return fechaFormateada;

        },
        formatoNumero(numero) {
            return new Intl.NumberFormat("en-US").format(numero)
        },
    },
    computed: {
        totales() {
            return {
                monto: this.pagosMonto.reduce((acc, pago) => acc + pago.montoPagado, 0),

            };
        }
    }
}
</script>

<style></style>